































































































import $ from 'jquery';
import { Component, Prop, Vue } from "vue-property-decorator";

interface Link {
    path: string;
    text: string;
    textShort: string;
}

@Component
export default class Header extends Vue {
  private links: Array<Link> = [
    //{path: "/agenda", text: "Agenda", textShort: "Agenda"},
    {path: "/reservation", text: "Réservation", textShort: "Réservation"},
    {path: "/a-propos", text: "À propos de moi", textShort: "À propos"},
    {path: "/boite-outils", text: "Boite à outils", textShort: "Boite à outils"}
  ];
  private cocoonLinks: Array<Link> = [
    
    {path: "/consultation-allaitement", text: "L'allaitement (IBCLC)", textShort: "Allaitement (IBCLC)"},
    {path: "/atelier-portage-physiologique", text: "Le portage", textShort: "Portage"},
    {path: "/soin-rituel-rebozo", text: "Rituel Rebozo", textShort: "Rebozo"},
    {path: "/cercles-mamans-bebes", text: "Les cercles des Mamans", textShort: "Cercles des mamans"},
    
  ];
  private visioLinks: Array<Link> = [
    
    {path: "/consultation-allaitement-en-ligne", text: "L'allaitement (IBCLC)", textShort: "Allaitement - En ligne"},
    {path: "/consultation-parentalite-en-ligne", text: "La parentalité", textShort: "Parentalité - En ligne"},
    
  ];
  private isMobileMenuOpen = false;

  closeMobileMenu() {
    this.isMobileMenuOpen = false;
    window.scrollTo(0,0);
  }
}
