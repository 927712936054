



























































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WorkshopItem extends Vue {
  @Prop() private title!: string;
  @Prop() private content!: string;
  @Prop() private buttonText!: string;
  @Prop() private buttonText2?: string;
  @Prop() private buttonEvent!: () => void;
  @Prop() private imgFileName!: string;
  @Prop() private isCollective!: boolean;
  @Prop() private duration?: number;
  @Prop() private price?: number;
  @Prop() private info?: string;
  @Prop() private path!: string;
  @Prop() private path2?: string;
  @Prop() private absolutePath?: boolean;
}
