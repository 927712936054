



































































































































































































import { Component, Vue } from "vue-property-decorator";

import Citation from "@/components/Citation.vue";
import WorkshopItem from "@/components/WorkshopItem.vue";

@Component({
  components: { Citation, WorkshopItem },
})
export default class KangarooBabyWorkshop extends Vue {

  private babyBenefits = [
    'Favorise la création du lien',
    'Apaise et rassure le bébé',
    'Diminue des pleurs',
    'Sentiment de sécurité, calme les maux de bébé',
    'Favorise l’autonomie',
    'Favorise et aide l’allaitement et l’endormissement',
    'Limite les déformations crâniennes, soulage le RGO et les affections ORL',
    'Meilleur développement psycho-moteur'
  ]

  private carrierBenefits = [
    'Libère les bras et dégage du temps',
    'Facilite la lactation et l’allaitement',
    'Favorise la création du lien et la communication',
    'Laisse une place à l’autre parent, aux grands-parents',
    'Diminue les risques de dépression du post-partum',
    'Accroit la patience et la bienveillance du parent',
    'Comble les besoins de proximité de bébé',
    'Système peu encombrant et tout terrain'
  ]

  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    (document.getElementById("contact-form-input-phone-or-mail") as HTMLInputElement).select();
  }
}
